import { HttpService } from "./HttpService";

export interface UpdateTotalsRequest {
    totalDate: string;
    totals: Total[];
}

interface Total {
    client_id: number | string,
    price:  number;
}

export class TotalService {

    /**
     * Update on Server
     * @returns boolean
     */
    static async updateTotals(payload: UpdateTotalsRequest) {
        const response = await HttpService.post(`/totals/update`, payload);
        return response?.data;
    }


    /**
     * Totals for a day
     * @returns boolean
     */
    static async dayTotals(date: string) {
        const response = await HttpService.get(`/totals/day/${date}`, {});
        return response?.data;
    }


    /**
     * Totals for a month
     * @returns boolean
     */
    static async monthTotals(year: number, month: number) {
        const response = await HttpService.get(`/totals/month/${year}/${month}`, {});
        return response?.data;
    }
}