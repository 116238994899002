import { HttpService } from "./HttpService";
import { User } from "./UserService";

export interface ClientListResponse {
    address: string;
    id: number;
    postalCode: string;
    name: string;
   
}

export interface ClientItemResponse {
    address: string;
    id: number;
    postalCode: string; 
    name: string;
}

export interface ClientMonthResponse {
    price: number;
    totalDate: string; 
}


export class ClientService {


    /**
     * Get Client List from server
     * @returns ClientListResponse[] Response
     */
    static async list(): Promise<ClientListResponse[]> {
        const response = await HttpService.get('/clients', {});
        return response?.data;
    }

    /**
     * Get Client Item from server
     * @returns ClientItemResponse Response
     */
    static async item(id: number): Promise<ClientItemResponse> {
        const response = await HttpService.get(`/clients/item/${id}`, {});
        return response?.data;
    }

    /**
     * Get Client Item from server
     * @returns ClientItemResponse Response
     */
    static async publicItem(id: number): Promise<ClientItemResponse> {
        const response = await HttpService.get(`/clients/public-item/${id}`, {});
        return response?.data;
    }

    /**
     * Delet Client from server
     * @returns boolean
     */
    static async delete(id: number) {
        const response = await HttpService.delete('/clients/delete', {id});
        return response?.data;
    }

    /**
     * Update on Server
     * @returns boolean
     */
    static async update(payload: ClientItemResponse) {
        const response = await HttpService.post(`/clients/update`, payload);
        return response?.data;
    }


    /**
     * Create on Server
     * @returns boolean
     */
    static async create(payload: ClientItemResponse) {
        const response = await HttpService.post(`/clients/create`, payload);
        return response?.data;
    }

    /**
     * Totals for a month for a specific client
     * @returns boolean
     */
    static async monthTotals(client_id: number, year: number, month: number): Promise<ClientMonthResponse[]> {
        const response = await HttpService.get(`/clients/${client_id}/month/${year}/${month}`, {});
        return response?.data;
    }

}