import { Menu } from "antd";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import './index.scss';
import { UserService } from "../../services/UserService";
import { Route } from "../../types/route";


export class MenuComponent extends React.Component {

    state: {
        current: string,
        routes: Route[],

    } = {
        current: '',
        routes: [],
      };

    constructor(props: any) {
        super(props);

        /**
         * If accessToken is not in localStorage redirect to login page
         */
        if(!UserService.getAccessToken()) {
            window.location.href = '/';
        }
    }

    /**
     * On component mount
     */
    componentDidMount(): void {
         // Set routes
         this.setState({
            routes: UserService.getRoutes(),
        });
    }
    
    /**
     * On menu item click
     * @param e 
     */
    handleClick = (e: any) => {
        this.setState({
            current: e.key,
        });
    };

    /**
     * Do logout call
     */
    doLogout() {
        UserService.clearUserTokens();
        window.location.href = '/';
    }

    render() {
        return (
            <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                {
                    <Menu.Item key="prices">
                        <Link to="/home/prices">Totale giornaliero</Link>
                    </Menu.Item>
                }

                {
                    <Menu.Item key="totals">
                        <Link to="/home/totals">Totali mensili</Link>
                    </Menu.Item>
                }
                
                {
                    <Menu.Item key="clients">
                        <Link to="/home/clients">Panetterie</Link>
                    </Menu.Item>
                }
                        
                <Menu.Item key="logout" onClick={() => this.doLogout()}>
                    Logout
                </Menu.Item>
            </Menu>
        );
    }
}

export class Home extends React.Component {

    render() {
        return <>
            <MenuComponent />
            <div className="page-container">
                <Outlet />
            </div>
        </>;
    }
}