import { EuroCircleOutlined, EyeOutlined, RedEnvelopeOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Input, Row, Select, Table, Tooltip } from "antd";
import { ClientListResponse, ClientService } from "../../services/ClientService";
import { useEffect, useMemo, useState } from "react";
import { TotalService } from "../../services/TotalService";
import { PageLoader } from "../../components/pageLoader";

const getToday = (date = new Date()) => {
        return {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        };
    }

const monthes = [
    {value: 1, label: 'Gennaio'},
    {value: 2, label: 'Febbraio'},
    {value: 3, label: 'Marzo'},
    {value: 4, label: 'Aprile'},
    {value: 5, label: 'Maggio'},
    {value: 6, label: 'Giugno'},
    {value: 7, label: 'Luglio'},
    {value: 8, label: 'Agosto'},
    {value: 9, label: 'Settembre'},
    {value: 10, label: 'Ottobre'},
    {value: 11, label: 'Novembre'},
    {value: 12, label: 'Dicembre'},
];

const years = (() => {
    const nowYear = getToday().year;

    return [
        {value: nowYear - 1, label: nowYear - 1},
        {value: nowYear, label: nowYear},
        {value: nowYear + 1, label: nowYear + 1},
    ];
})();

function getColumns(openClientPage: (id: number) => void) {

    return [
        {
            title: 'Cliente',
            key: 'client',
            render: (element: any) => `${element?.name}`
        },
        {
            title: 'Totale mensile',
            key: 'total',
            render: (element: any) => `${element?.price}€`
        },
        {
            title: '#',
            key: 'send',
            render: (element: any) => <>
                <Row gutter={16}>
                    {/* <Col>
                        <Button>
                            <RedEnvelopeOutlined />
                            Invia messaggio
                        </Button>
                    </Col> */}
                    <Col>
                        <Tooltip title="Pagina panificio">
                            <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => openClientPage(element.id)} />
                        </Tooltip>
                    </Col>
                </Row>
                

                
            </>
        },
    ];

}

export const Totals = () => {
    

    const [clients, setClients] = useState<ClientListResponse[]>([]);
    const [totals, setTotals] = useState<Record<number, number>>({});
    const [month, setMonth] = useState<number>(getToday().month);
    const [year, setYear] = useState<number>(getToday().year);
    const [dataSource, setDataSource] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const visualMonth = useMemo(() => monthes.find(el => el.value == month)?.label, [month]);

    /**
     * Automatic month total calculator
     */
    const monthTotal = useMemo(() => Object.values(totals).reduce((acc, val: any) => acc + parseFloat(val), 0) , [totals]);

    /**
     * Start loading clients
     */
    useEffect(() => {
        (async() => {
            const list =  await ClientService.list();
            setClients(list);
        })();
    }, []);

    /**
     * On date change, retrieve data
     */
    useEffect(() => {
        (async() => {
            setLoading(true);
            const totals = await TotalService.monthTotals(year, month);
            setTotals(totals);
            setLoading(false);
        })();
    }, [year, month]);

    /**
     * On totals and clients values setted
     */
    useEffect(() => {
        
        setDataSource(
            clients.map(el => ({
                id: el.id,
                name: el.name,
                price: totals[el.id] || 0
            }))
        )
    }, [totals, clients]);

    return <>

        <h3>Totali per il mese di {visualMonth} {year} </h3> 
        <Row gutter={16} align={'middle'}>
            <Col>
                Seleziona mese e anno
            </Col>
            <Col >
                <Select
                    style={{width: 150}}
                    options={monthes}
                    value={month}
                    onChange={e => setMonth(e)}
                />
            </Col>
            <Col >
                <Select
                    style={{width: 150}}
                    options={years}
                    value={year}
                    onChange={e => setYear(e)}
                />
            </Col>
        </Row>

        {loading && <PageLoader />}
        
        {
            !loading &&
            <>
                <Table 
                    columns={getColumns((client_id) => {
                        window.open(`/bakery/${client_id}/${year}/${month}`);
                    })} 
                    dataSource={dataSource} 
                    pagination={false} 
                    style={{marginTop: 20}}
                />

                <Alert
                    message={<>Totale {visualMonth} {year}</>}
                    description={<>{monthTotal}€</>}
                    type="info"
                    showIcon
                    style={{marginTop: 20}}
                />
            </>
        }
        
    </>

}
