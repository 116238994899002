import { EuroCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Input, Row, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ClientListResponse, ClientService } from "../../services/ClientService";
import { TotalService, UpdateTotalsRequest } from "../../services/TotalService";
import { PageLoader } from "../../components/pageLoader";

function getColumns(onPriceChange: (id: string, price: string) => void) {

    return [
        {
            title: 'Cliente',
            key: 'id',
            render: (element: any) => `${element?.name}`
        },
        {
            title: 'Totale giornaliero',
            key: 'name',
            render: (element: any) => 
                <Input 
                    suffix={<EuroCircleOutlined />} 
                    placeholder="Usare il punto per i decimali" 
                    value={element?.price || ''} 
                    onChange={(e) => {
                        onPriceChange(element.id, e.target.value);
                        
                    }}
                />
        },
    ];

}

export const Prices = () => {

    const formatDate = (date = new Date()) => {
        return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    }
    
    const [clients, setClients] = useState<ClientListResponse[]>([]);
    const [totals, setTotals] = useState<Record<number, number>>({});
    const [date, setDate] = useState<string>(formatDate());
    const [dataSource, setDataSource] = useState<any>([]);
    const visualDate = useMemo(() => {
        const [year, month, day] = date.split('-');

        return `${day}/${month}/${year}`;
    }, [date]);

    const [loading, setLoading] = useState(false);

    

    /**
     * Automatic day calculator
     */
    const dayTotal = useMemo(() => Object.values(totals).reduce((acc, val: any) => acc + parseFloat(val), 0) , [totals]);
    
    /**
     * Start loading clients
     */
    useEffect(() => {
        (async() => {
            const list =  await ClientService.list();
            setClients(list);
        })();
    }, []);

    /**
     * On date change, retrieve data
     */
    useEffect(() => {
        (async() => {
            setLoading(true);
            const totals = await TotalService.dayTotals(date)
            setTotals(totals);
            setLoading(false);
        })();
    }, [date]);
    
    /**
     * On totals and clients values setted
     */
    useEffect(() => {
        
        setDataSource(
            clients.map(el => ({
                id: el.id,
                name: el.name,
                price: totals[el.id] || 0
            }))
        )
    }, [totals, clients]);

    /**
     * Save data
     */
    const save = async () => {
        const payload: UpdateTotalsRequest = {
            totalDate: date,
            totals: Object.entries(totals).map(([client_id, price]) => ({ client_id, price: price || 0 }))
        }


        setLoading(true);
        
        /**
         * Send data to server
         */
        await TotalService.updateTotals(payload);

        setLoading(false);

        alert('Modificato con successo!');
        
    }

    return <>

        <h3>Totali giornalieri per il giorno: {visualDate} </h3> 

        <Row gutter={16} align={'middle'}>
            <Col>
                Data
            </Col>
            <Col>
                <DatePicker 
                    format={'YYYY-MM-DD'}
                        // disabledDate={d => !d || d.date() !== 1 || d.isBefore(this.state.cardStartLimit)} 
                    onChange={e => {
                        setDate(`${e?.year()}-${e?.month()! + 1}-${e?.date()?.toString().padStart(2, '0')}`);
                    }}
                />
            </Col>
        </Row>

        {loading && <PageLoader />}

        {
            !loading &&
            <>
                <Table 
                    columns={getColumns((id, price: any) => {
                        console.log('price', price);
                        
                        setTotals({
                            ...totals,
                            [id]: price,
                        })
                    })} 
                    dataSource={dataSource} 
                    pagination={false} 
                    style={{marginTop: 20}}
                />

                <Alert
                    message="Totale"
                    description={<>{dayTotal}€</>}
                    type="info"
                    showIcon
                />

                <Row justify={'end'}>
                    <Col>
                        <Button type="primary" htmlType="submit" style={{marginTop: 20}} onClick={save}>
                            <SaveOutlined />
                            Salva
                        </Button>
                    </Col>
                </Row>
            </>
            
        }
        
    </>

}
