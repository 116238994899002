import React from "react";
import { PageLoader } from "../../components/pageLoader";
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ClientItemResponse, ClientService } from "../../services/ClientService";
import { VALIDATIONS } from "../../utils/validations";

const { Title } = Typography;

export class ClientElement extends React.Component<any,any> {

    state : {
        item: ClientItemResponse | undefined,
        itemId: number,
        loaded: boolean,
    } = {
        item: undefined,
        itemId: -1, // Default 0, is used only when needed
        loaded: false,
    };

    /**
     * Check param data on component load
     */
    async componentDidMount() {

        // Read item id to know if is an update or an insert
        const itemId = window.location.href.split('/')[6]


        // if passed in GET load it from state
        if(itemId) {
            this.loadItem(parseInt(itemId));
        } else {
            // Remove Loader
            this.setState({
                loaded: true
            });
        }

    }

    /**
     * Load item from Server
     */
    async loadItem(id: number) {

        try {
            const response = await ClientService.item(id);

            // Save response
            this.setState({
                item: response,
                loaded: true,
                itemId: id, // Save also item ID
            });

        } catch(e) {

            // Return to list
            window.location.href = `/home/clients`

        }
        
    }

    /**
     * Is an item upadte? Or is an insert?
     * @returns 
     */
    isUpdate(): boolean {
        return this.state.item !== undefined;
    }

    /**
     * On form submit when valied
     * @param values
     */
    async onFinish(values: any) {
        
        /**
         * Show loader
         */
        this.setState({
            loaded: true,
        });

        try {

            if( this.isUpdate() ) {
                await ClientService.update({
                    id: this.state.itemId,
                    ...values
                });
            } else {
                await ClientService.create({
                    ...values
                });
            }

            // Return to list
            window.location.href = `/home/clients`;

        } catch(e) {
            alert('Server Error');

        } finally {

        }
    }

    /**
     * Render data
     * @returns 
     */
    render() {

        /**
         * if not loaded -> return empty
         */
        if(!this.state.loaded) {
            return <PageLoader />;
        }

        return <>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Title>{this.isUpdate() ? 'Modifica' : 'Aggiungi'} Panetteria</Title>
                </Col>        
            </Row>


            <Form
                name="basic"
                onFinish={(data: any) => this.onFinish(data)}
                autoComplete="off"
                initialValues={{...this.state.item}}
            >
                <Row gutter={16}>
                    <Col md={8} xs={24}>
                        <Form.Item
                            label="Nome Panificio"
                            name="name"
                            rules={[{ required: true, message: 'Campo obbligatorio' }]}
                            >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item
                            label="Indirizzo"
                            name="address"
                            rules={[{ required: true, message: 'Campo obbligatorio' }]}
                            >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item
                            label="CAP"
                            name="postalCode"
                            rules={[
                                ...VALIDATIONS.CAP,
                                { required: true, message: 'Campo obbligatorio' }
                            ]}
                            >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={10} xs={24}>
                        <Form.Item
                            label="Cellulare (inserire +39)"
                            name="phoneNumber"
                            // rules={[{ required: true, message: 'Campo obbligatorio' }]}
                            >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} xs={24}>
                        <Form.Item>
                            <Space>
                                <Link to='/home/clients'>
                                    <Button type='primary' danger icon={<ArrowLeftOutlined />}>Indietro</Button>
                                </Link>
                                <Button type='primary' htmlType="submit">Salva</Button>
                            </Space>
                            
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            
        </>;
    }

}