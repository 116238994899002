import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Prices } from './pages/prices';
import { Home } from './pages/home';
import { Totals } from './pages/totals';
import { Bakery } from './pages/bakery';
import { Login } from './pages/login';
import { useEffect, useState } from 'react';
import { HttpService } from './services/HttpService';
import { ClientElement, ClientList } from './pages/clients';

function App() {

  const [ready, setReady] = useState<boolean>(false);

  /**
   * Set axios interceptors
   */
  useEffect(() => {
    // Set Axios Intrceptor
		HttpService.setAxiosInterceptor();

    setReady(true);
  }, []);

  return (
    <>
      {
        ready &&
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="bakery/:id/:year/:month" element={<Bakery />} />

          <Route path="home" element={<Home />}>
            <Route path="prices" element={<Prices />} />
            <Route path="totals" element={<Totals />} />

            <Route path="clients" element={<ClientList />} />
            <Route path="clients/item/:id?" element={<ClientElement />} />
            
          </Route>
        </Routes>
      }
      
    </>
  );
}

export default App;
