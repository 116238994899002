import { EuroCircleOutlined, RedEnvelopeOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Input, Row, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ClientElement } from "../clients";
import { ClientItemResponse, ClientMonthResponse, ClientService } from "../../services/ClientService";
import { PageLoader } from "../../components/pageLoader";

const monthes = [
    {value: 1, label: 'Gennaio'},
    {value: 2, label: 'Febbraio'},
    {value: 3, label: 'Marzo'},
    {value: 4, label: 'Aprile'},
    {value: 5, label: 'Maggio'},
    {value: 6, label: 'Giugno'},
    {value: 7, label: 'Luglio'},
    {value: 8, label: 'Agosto'},
    {value: 9, label: 'Settembre'},
    {value: 10, label: 'Ottobre'},
    {value: 11, label: 'Novembre'},
    {value: 12, label: 'Dicembre'},
];

const formatDate = (date: string) => {
    const [year, month, day] = date.split('-');

        return `${day}/${month}/${year}`
}

function getColumns() {

    return [
        {
            title: 'Data',
            key: 'date',
            render: (element: ClientMonthResponse) => `${formatDate(element?.totalDate)}`
        },
        {
            title: 'Totale',
            key: 'total',
            render: (element: ClientMonthResponse) => `${element?.price}€`
        }
    ];

}

export const Bakery = () => {
    const {id, year, month} = useParams();

    const [client, setClient] = useState<ClientItemResponse>();
    const [totals, setTotals] = useState<ClientMonthResponse[]>([]);
    const visualMonth = useMemo(() => monthes.find(el => el.value == parseInt(month as any) || 0)?.label, [month]);
    const [loading, setLoading] = useState(false);


    /**
     * Automatic month total calculator
     */
    const monthTotal = useMemo(() => Object.values(totals).reduce((acc, val) => acc + val.price, 0) , [totals]);

    /**
     * Load client
     */
    useEffect(() => {
        (async () => {
            const response = await ClientService.publicItem(parseInt(id!));
            setClient(response);
        })();
    }, []);


    /**
     * Load totals
     */
    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await ClientService.monthTotals(parseInt(id!), parseInt(year!), parseInt(month!));
            setTotals(response);
            setLoading(false);
        })();
    }, []);
    
    /**
     * if loading
     */
    if(loading) {
        return <PageLoader />
    }

    return <>

        <h1>Panificio Amedeo</h1>

        <h3>Totali per il mese di {visualMonth} {year} per {client?.name} </h3> 

        {
            monthTotal > 0 &&
            <Table 
                columns={getColumns()} 
                dataSource={totals} 
                pagination={false} 
            />
        }
        

        <Alert
            message={<>Totale {visualMonth} {year}</>}
            description={<>{monthTotal}€</>}
            type="info"
            showIcon
            style={{marginTop: 20}}
        />
    </>

}
